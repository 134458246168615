import { App } from './app.class';
import { Footer } from './base';
import { Fetch } from '../fetch.class';

export class Page {

    constructor() {

        // Wechsel des Abrechnungszeitraumes
        const $switchBilling = document.querySelector("#switch-billing");
        const $showOnYearlyElements = document.querySelectorAll(".show-on-yearly");
        const $showOnMonthlyElements = document.querySelectorAll(".show-on-monthly");
        $switchBilling.addEventListener('switch-change', event => {
            let checked = event.detail?.checked ?? false;
            $showOnYearlyElements.forEach($ele => {
                $ele.style.display = checked ? "inline-block" : "none";
            });
            $showOnMonthlyElements.forEach($ele => {
                $ele.style.display = checked ? "none" : "inline-block";
            });
        }, false);

        // Paddle initialisieren
        Paddle.Setup({ vendor: 120516 });

        // Paddle Checkout
        const $btnsCheckout = document.querySelectorAll('button.paddle-checkout');
        $btnsCheckout.forEach($btn => {
            $btn.addEventListener('click', event => {

                const $inputBilling = document.querySelector('input[name=billing_yearly]');
                const isYearly = $inputBilling.value;
                const $ele = event.currentTarget;

                let productId = 0;
                if(isYearly == "true")  productId = parseInt( $ele.getAttribute('data-productid-yearly'), 10 );
                else                    productId = parseInt( $ele.getAttribute('data-productid-monthly'), 10 );

                const account_email = $ele.getAttribute('data-account-email');
                const account_id = parseInt( $ele.getAttribute('data-account-id') );
                const locale = $ele.getAttribute('data-locale');
                const title = $ele.getAttribute('data-title');
                const plan = $ele.getAttribute('data-plan');

                const passthrough = {};
                passthrough.account_id = account_id;
                passthrough.lang = locale;

                const para = {
                    product: productId,
                    passthrough: JSON.stringify(passthrough),
                    allowQuantity: false,
                    locale: locale,
                    email: account_email,
                    title: title,
                    success: App.getUrl('panel/membership/order_processing', { to_plan: plan }),
                };

                Paddle.Checkout.open(para);
            });
        });

        // Paddle Tarifwechsel
        const $btnsChange = document.querySelectorAll('button.paddle-change');
        $btnsChange.forEach($btn => {
            $btn.addEventListener('click', event => {

                const $ele = event.currentTarget;

                // Attribute übernehmen
                const $btnChangeNow = document.querySelector('#modal-paddlechange-request button.paddle-change-now');
                $btnChangeNow.setAttribute('data-productid-yearly', $ele.getAttribute('data-productid-yearly'));
                $btnChangeNow.setAttribute('data-productid-monthly', $ele.getAttribute('data-productid-monthly'));
                $btnChangeNow.setAttribute('data-subscription-id', $ele.getAttribute('data-subscription-id'));

                // Modal mit Nachfrage zu Tarifwechsel öffnen
                window.Modals['modal-paddlechange-request'].open();
            });
        });
        const $btnChangeNow = document.querySelector('#modal-paddlechange-request button.paddle-change-now');
        if($btnChangeNow) {
            $btnChangeNow.addEventListener('click', event => {

                const $inputBilling = document.querySelector('input[name=billing_yearly]');
                const isYearly = $inputBilling.value;
                const $ele = event.currentTarget;

                let productId = 0;
                if(isYearly === "true")     productId = parseInt( $ele.getAttribute('data-productid-yearly'), 10 );
                else                        productId = parseInt( $ele.getAttribute('data-productid-monthly'), 10 );

                const subscription_id = parseInt( $ele.getAttribute('data-subscription-id'), 10 );

                // Tarifwechsel per Ajax vornehmen
                const data = new FormData();
                data.append('product_id', productId);
                data.append('subscription_id', subscription_id);

                new Fetch().post(App.getUrl('panel/membership/change_paid_plan'), data)
                .then(response => {
                    window.Modals['modal-paddlechange-success'].open();
                })
                .catch(error => {
                    alert("Error: " + error.message);
                })
                .finally(() => {

                });
            });
        }
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('upgrade')) {
        new Page();
    }
});