// Klasse Switch
export class Switch {

    constructor($ele) {

        this.$container = $ele;
        this.$input = this.$container.querySelector("input[type=hidden]");
        this.$button = this.$container.querySelector("button");

        // Klick auf Switch
        this.$button.addEventListener("click", event => {
            this.toggle();
        });

        // mit Leertaste umschalten
        document.addEventListener("keyup", event => {
            if(event.key === "Space character" && document.activeElement === this.$container) {
                this.toggle();
            }
        });

        // Status initialisieren
        this.#setStatus();
    }

    toggle() {
        this.$container.classList.toggle("on");
        this.#setStatus();
    }

    #setStatus() {
        const on = this.$container.classList.contains("on");

        // input setzen
        this.$input.value = on;

        // aria setzen
        this.$button.setAttribute("aria-checked", on);

        // event
        this.triggerChange();
    }

    triggerChange() {
        const on = this.$container.classList.contains("on");
        this.$container.dispatchEvent(new CustomEvent('switch-change', { detail: { checked: on } }));
    }
}