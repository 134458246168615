import { App } from './app.class';
import { Fetch } from '../fetch.class';

/* Klasse Footer */
export class Footer {

    constructor() {

        // Nachricht schreiben
        const $formMessage = document.querySelector('#modal-footer-msg form');
        $formMessage.addEventListener('submit', event => {
            event.preventDefault();
            
            // Nachricht senden
            const $form = event.currentTarget;
            const formData = new FormData($form);
            const $errorMsg = $form.querySelector(".error-msg");

            // Fehlermeldung ausblenden
            $errorMsg.classList.add("hidden");
            $errorMsg.setAttribute("aria-hidden", "true");

            // Sendebutton deaktivieren
            App.setSubmitBtnLoading($form);

            // Senden
            new Fetch().post($form.getAttribute("action"), formData)
            .then(response => {

                // Modal schliessen
                window.Modals["modal-footer-msg"].close();
                    
                // Erfolgsmeldung anzeigen
                const $modal = document.querySelector("#modal-footer-msg");
                const header = $modal.getAttribute('data-msg-success-title');
                const text = $modal.getAttribute('data-msg-success-text');
                this.showSuccessModal(header, text);
                    
                // Formular zurücksetzen
                $form.reset();
            })
            .catch(error => {

                const $textContent = $errorMsg.querySelector(".text-content");
                $textContent.textContent = error.message ?? "Unknown error";

                $errorMsg.classList.remove("hidden");
                $errorMsg.removeAttribute("aria-hidden");
            })
            .finally(() => {

                // Sendebutton zurücksetzen
                App.setSubmitBtnActive($form);
            });
        });

        // Newsletter abonnieren
        const $formNewsletter = document.querySelector('#modal-footer-newsletter form');
        $formNewsletter.addEventListener('submit', event => {
            event.preventDefault();

            const $form = event.currentTarget;
            const formData = new FormData($form);
            const $errorMsg = $form.querySelector(".error-msg");

            // Fehlermeldung ausblenden
            $errorMsg.classList.add("hidden");
            $errorMsg.setAttribute("aria-hidden", "true");

            // Sendebutton deaktivieren
            App.setSubmitBtnLoading($form);

            // Senden
            new Fetch().post(App.getLang()+'/submit_newsletterregistration', formData)
            .then(response => {

                // Modal schliessen
                window.Modals["modal-footer-newsletter"].close();

                // Modal anzeigen
                this.showSuccessModal(response.msg_header, response.msg_text);

                // Formular zurücksetzen
                $form.reset();
            })
            .catch(error => {

                const $textContent = $errorMsg.querySelector(".text-content");
                $textContent.textContent = error.message ?? "Unknown error";

                $errorMsg.classList.remove("hidden");
                $errorMsg.removeAttribute("aria-hidden");

                $form.querySelector("input[name=email]").focus();
            })
            .finally(() => {

                // Sendebutton zurücksetzen
                App.setSubmitBtnActive($form);
            });
        });
    }

    showSuccessModal(header, text) {
        const $modal = document.querySelector("#modal-footer-success");
        $modal.querySelector('h3.header').textContent = header;
        $modal.querySelector('p.msg').textContent = text;
        window.Modals['modal-footer-success'].open();
    }
}

// Initialisierung wenn DOM geladen
window.Footer = null;
App.domready(() => {
    const $footer = document.querySelector('footer');
    if($footer) {
        window.Footer = new Footer();
    }
});