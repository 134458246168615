// Klasse Popup
export class Popup {

    constructor($ele, callbackOpen=null) {

        this.$container = $ele;
        this.callbackOpen = callbackOpen;

        // Button
        this.$btn = this.$container.querySelector(".popup-btn");
        if(!this.$btn) {
            console.error("Popup-Button nicht gefunden!");
            return;
        }

        // Popup Display
        if($ele.hasAttribute("data-popup-display-selector")) {
            this.$popupDisplay = document.querySelector( $ele.getAttribute("data-popup-display-selector") );
        }
        else this.$popupDisplay = this.$container.querySelector(".popup-display");
        if(!this.$popupDisplay) {
            console.error("Popup-Element nicht gefunden!");
            return;
        }

        // Klick auf Button
        this.$btn.addEventListener("click", event => {
            event.stopPropagation();
            this.toggle();
        });

        // Close-Buttons im Popup
        const $closeBtns = this.$popupDisplay.querySelectorAll(".popup-close-btn");
        $closeBtns.forEach($btn => {
            $btn.addEventListener("click", event => {
                this.close();
            });
        });
    }

    // Popup einblenden
    open() {
        if(!this.$container.classList.contains("open")) {
            this.close = this.close.bind(this);
            document.addEventListener("click", this.close);
            document.addEventListener("keyup", this.close);
            
            this.$popupDisplay.removeAttribute("hidden");
            this.$btn.setAttribute("aria-expanded", "true");
            const reflow = this.$popupDisplay.offsetHeight;

            this.$container.classList.add("open");

            if(this.callbackOpen) {
                this.callbackOpen(this);
            }
        }
    }

    // Popup schliessen
    close(event=null) {
        if(this.$container.classList.contains("open")) {

            if(event) {

                if(event.type === "click") {
                    let targetElement = event.target;
                    do {
                        if (targetElement == this.$popupDisplay) {
                            return;
                        }
                        targetElement = targetElement.parentNode;
                    } while (targetElement);
                    event.preventDefault();
                }

                else if(event.type === "keyup") {
                    if(event.key !== "Escape") {
                        return;
                    }
                }

            }

            document.removeEventListener("click", this.close);
            document.removeEventListener("keyup", this.close);

            this.$btn.setAttribute("aria-expanded", "false");

            const st = window.getComputedStyle(this.$popupDisplay);
            if(st.transition !== "all 0s ease 0s") {
                this.$popupDisplay.addEventListener('transitionend', event => {
                    this.$popupDisplay.setAttribute("hidden", true);
                }, { once: true });
            }
            else this.$popupDisplay.setAttribute("hidden", true);

            this.$container.classList.remove("open");
        }
    }

    // Popup öffnen/schliessen
    toggle() {
        if(this.$container.classList.contains("open")) {
            this.close();
        }
        else {
            this.open();
        }
    }
}