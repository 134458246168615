import { App } from './app.class';
import { Footer } from './base';
import { Fetch } from '../fetch.class';

export class Page {

    constructor() {

        // Paralax-Scrolling der Wolken
        const $cloud2 = document.querySelector('.clouds2');
        const $cloud3 = document.querySelector('.clouds3');
        document.addEventListener('scroll', event => {
            const pos = window.scrollY;
            $cloud2.style.bottom = '-'+pos/7/2+'px';
            $cloud3.style.bottom = '-'+pos/7+'px';
        });

        // Registrierung
        const $formRegister = document.querySelector('#register form');
        $formRegister.addEventListener('submit', event => {
            event.preventDefault();

            // Registrierung übermitteln
            const $form = event.currentTarget;
            const formData = new FormData($form);

            // Sendebutton deaktivieren
            App.setSubmitBtnLoading($form);

            // Senden
            new Fetch().post(App.getLang()+'/submit_formregister', formData)
            .then(response => {

                // Erfolgs-Modal anzeigen
                const $modal = document.querySelector("#modal-register-success");
                const $cont = $modal.querySelector('.cont');
                const form_code = response.form_code ?? '';
                if(form_code !== '') {
                    const $btnPanel = $modal.querySelector('a.btn-panel');
                    if($btnPanel)
                        $btnPanel.setAttribute('href', $btnPanel.getAttribute('href')+form_code);
                }
                $cont.innerHTML = response.info_msg ?? '';
                window.Modals['modal-register-success'].open();

                // Formular zurücksetzen
                $form.reset();
            })
            .catch(error => {

                // bereits Account vorhanden?
                let existing_account = false;
                if(error && error.response?.data?.existing_account === true) {
                    existing_account = true;
                }

                // Fehlermeldung anzeigen
                const $modal = document.querySelector("#modal-register-error");
                const $header = $modal.querySelector('h3');
                const $cont = $modal.querySelector('.cont');
                const $btnPanel = $modal.querySelector('a.btn-panel');
                const $btnClose = $modal.querySelector('button.close');
                if(existing_account) {
                    $header.classList.add('hidden');
                    $btnPanel.classList.remove('hidden');
                    $btnClose.classList.add('hidden');
                } else {
                    $header.classList.remove('hidden');
                    $btnPanel.classList.add('hidden');
                    $btnClose.classList.remove('hidden');
                }
                $cont.textContent = error.message;
                window.Modals['modal-register-error'].open();
            })
            .finally(() => {

                // Sendebutton zurücksetzen
                App.setSubmitBtnActive($form);
            });
        });
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('home')) {
        new Page();
    }
});