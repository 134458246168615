import { App } from './app.class';
import { Footer } from './base';
import { Fetch } from '../fetch.class';
import ClipboardJS from 'clipboard';

export class Page {

    constructor() {

        // Quellcode kopieren
        const clipboard = new ClipboardJS('button.copy');
        clipboard.on('success', event => {
            event.clearSelection();

            const $btn = event.trigger;
            $btn.classList.add('done');

            setTimeout(() => {
                $btn.classList.remove('done');
            }, 1500);
        });

        // Modal Elemente
        const $modal = document.querySelector("#modal-register");
        const $contentInput = $modal.querySelector('.content_input');
        const $contentSuccess = $modal.querySelector('.content_success');
        const $contentError = $modal.querySelector('.content_error');

        // Button Adresse-anfordern
        const $btnRequest = document.querySelector("button.request");
        if($btnRequest) {
            $btnRequest.addEventListener('click', event => {

                // Modal zurücksetzen
                $contentInput.classList.remove('hidden');
                $contentSuccess.classList.add('hidden');
                $contentError.classList.add('hidden');

                // Modal öffnen
                window.Modals['modal-register'].open();
            });
        }

        // Button Zurück (Fehler)
        const $btnErrorBack = $modal.querySelector('button.back');
        if($btnErrorBack) {
            $btnErrorBack.addEventListener('click', event => {

                // Modal zurücksetzen
                $contentInput.classList.remove('hidden');
                $contentSuccess.classList.add('hidden');
                $contentError.classList.add('hidden');
            });
        }

        // Registrierung senden
        const $formRegister = document.querySelector('#modal-register form');
        $formRegister.addEventListener('submit', event => {
            event.preventDefault();

            // Registrierung übermitteln
            const formData = new FormData($formRegister);

            // Sendebutton deaktivieren
            App.setSubmitBtnLoading($formRegister);

            // Senden
            new Fetch().post(App.getUrl(App.getLang()+'/submit_formregister'), formData)
            .then(response => {

                // Meldung einfügen
                const $infoText = $contentSuccess.querySelector('p.cont');
                $infoText.innerHTML = response.info_msg;

                // Sendeadresse einfügen
                const $infoCode = $contentSuccess.querySelector('pre > code');
                $infoCode.textContent = response.form_submission_url;

                // Panel-URL einfügen
                const $btnPanel = $contentSuccess.querySelector('a.btn');
                let url = $btnPanel.getAttribute("href");
                $btnPanel.setAttribute("href", url+response.form_code);

                // Anzeigebereiche
                $contentInput.classList.add('hidden');
                $contentError.classList.add('hidden');
                $contentSuccess.classList.remove('hidden');
            })
            .catch(error => {

                // Meldung einfügen
                const $msg = $contentError.querySelector('p.cont');
                $msg.textContent = error.message;

                // Anzeigebereiche
                $contentInput.classList.add('hidden');
                $contentSuccess.classList.add('hidden');
                $contentError.classList.remove('hidden');
            })
            .finally(() => {

                // Sendebutton zurücksetzen
                App.setSubmitBtnActive($formRegister);
            });
        });
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('sample-form')) {
        new Page();
    }
});