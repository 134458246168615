// Website-App
import { App } from "./website/app.class";
import { Popup } from './popup.class';
import { Modal } from './website/modal.class';
import { Switch } from './website/switch.class';

// Bereiche
import { Page as Page_Home } from "./website/page-home";
import { Page as Page_Plans } from "./website/page-plans";
import { Page as Page_Docs } from "./website/page-docs";
import { Page as Page_Blog } from "./website/page-blog";
import { Page as Page_Upgrade } from "./website/page-upgrade";
import { Page as Page_SampleForm } from "./website/page-sampleform";

// remove no-js class
App.domready(() => {
    const $html = document.querySelector("html");
    if ($html) {
        $html.classList.remove('no-js');
    }
});

// alle Popups initialisieren
App.domready(() => {
    window.Popups = [];
    const $popups = document.querySelectorAll(".popup");
    $popups.forEach($ele => {
        const p = new Popup($ele, instance => {
            window.Popups.forEach(p => {
                if(p !== instance) {
                    p.close();
                }
            });
        });
        window.Popups.push(p);
    });
});

// alle Modals initialisieren
App.domready(() => {
    window.Modals = [];
    const $modals = document.querySelectorAll(".modal");
    $modals.forEach($ele => {
        const id = $ele.id;
        window.Modals[id] = new Modal($ele, instance => {
            window.Modals.forEach(m => {
                if(m.id !== id) {
                    m.close();
                }
            });
        });
    }); 
});

// alles Switches initialisieren
App.domready(() => {
    window.Switches = [];
    const $switches = document.querySelectorAll(".switch");
    $switches.forEach($ele => {
        const s = new Switch($ele);
        window.Switches.push(s);
    }); 
});

// Matomo
let _paq = window._paq = window._paq || [];
_paq.push(["setDoNotTrack", true], ["disableCookies"], ['trackPageView'], ['enableLinkTracking']);
App.domready(() => {

    const u = "https://matomo.form.taxi/";
    _paq.push(['setTrackerUrl', u+'matomo.php'], ['setSiteId', '1']);

    const $script = document.createElement('script');
    $script.setAttribute('async', true);
    $script.setAttribute('src', u+'matomo.js');

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore($script, s);
});