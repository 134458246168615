import { App } from './app.class';
import { Footer } from './base';

export class Page {

    constructor() {

        // Links im Inhalt in neuem Tab öffnen
        const $links = document.querySelectorAll('main .content a:not([href^="mailto:"]');
        $links.forEach($link => {
            if($link.hostname != window.location.hostname) {
                $link.target = '_blank';
                $link.rel = 'noopener';
            }
        });

    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('blog')) {
        new Page();
    }
});