// Klasse Modal
export class Modal {

    constructor($ele, callbackOpen=null) {

        this.$container = $ele;
        this.callbackOpen = callbackOpen;
        this.id = $ele.id;
        this.$overlay = this.$container.querySelector(".overlay > div");
        this.$closeBtns = this.$container.querySelectorAll(".close");

        // Klick auf Öffnungs-Links
        const $links = document.querySelectorAll(`*[x-open-modal="${this.id}"]`);
        $links.forEach($link => {
            $link.addEventListener("click", event => {
                event.preventDefault();
                this.open();
            });
        });

    }

    open() {
        if(!this.$container.classList.contains("open")) {

            // Event-Listener
            this.close = this.close.bind(this);
            this.$overlay.addEventListener("click", this.close);
            document.addEventListener("keyup", this.close);
            if(this.$closeBtns) {
                this.$closeBtns.forEach($btn => {
                    $btn.addEventListener("click", this.close);
                });
            }

            // Hidden-Attribut
            this.$container.removeAttribute("hidden");
            const reflow = this.$container.offsetHeight;

            // Klasse hinzufügen
            this.$container.classList.add("open");

            // Focus auf erstes Eingabefeld
            const $input = this.$container.querySelector("input,textarea");
            if($input) {
                setTimeout(()=>{
                    $input.focus();
                }, 50);
            }

            // Callback ausführen
            if(this.callbackOpen) {
                this.callbackOpen(this);
            }
        }
    }

    close(event=null) {
        if(this.$container.classList.contains("open")) {

            if(event) {
                if(event.type === "click") {
                    event.preventDefault();
                }
                else if(event.type === "keyup") {
                    if(event.key !== "Escape") {
                        return;
                    }
                }
            }

            // Event-Listener entfernen
            this.$overlay.removeEventListener("click", this.close);
            document.removeEventListener("keyup", this.close);
            if(this.$closeBtns) {
                this.$closeBtns.forEach($btn => {
                    $btn.removeEventListener("click", this.close);
                });
            }

            // Hidden-Attribut
            this.$container.addEventListener('transitionend', event => {
                this.$container.setAttribute("hidden", true);
            }, { once: true });

            // Klasse entfernen
            this.$container.classList.remove("open");
        }
    }
}