import { App } from './app.class';
import { Footer } from './base';

export class Page {

    constructor() {

        this.$selectMenu = document.querySelector('select.menu');

        // Auswahl einer anderen Doku-Seite
        this.$selectMenu.addEventListener('change', event => {
            const url = this.$selectMenu.options[ this.$selectMenu.selectedIndex ].getAttribute('data-url');
            App.gotoUrl(url);
        });
    }
}

// Initialisierung wenn DOM geladen
App.domready(() => {
    if(document.querySelector('body').classList.contains('docs')) {
        new Page();
    }
});